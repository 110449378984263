import GraphQLErrorList from "components/graphql-error-list"
import Page from 'components/Page'
import SEO from 'components/seo'
import { graphql } from 'gatsby'
import React from 'react'
import { Container } from 'theme-ui'

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...LocalizedSettings
    }
    page: sanityPage(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      _rawModules(resolveReferences: {maxDepth: 10})
      _rawBody
      i18nRelatedInitiatives {
        title
        slug {
          current
        }
      }          
      relatedInitiatives {
        id
        title
        slug {
          current
        }
        i18n_path
        i18n_lang
        size
        categories {
          _id
          title {
            en_GB
          }
          invertedIcon {
            asset {
              altText
              url
            }
          }
        }
        mainImage {
          ...ImageWithHotspot
        }
      }
    }
  }
`;

const PageTemplate = ({ data, errors, path }) => {
  const page = {
    path,
    ...data.page,
    _rawModules: data.page && data.page._rawModules,
    relatedInitiatives:
      data.page.relatedInitiatives.map((item, index) => {
        return {
          ...item,
          title: data.page.i18nRelatedInitiatives[index]?.title,
          i18n_path: data.page.i18nRelatedInitiatives[index]?.slug.current,
        }
      }) || []
  }

  const isAbout = path.includes('/about')

  return (
    <React.Fragment>
      {errors && <SEO title="GraphQL Error" />}
      {page && <SEO title={page.title || "Untitled"} description={data?.site?.description?.localized || ''}  />}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {!isAbout && page && <Page {...page} inverted={false} />}
    </React.Fragment>
  );
};

export default PageTemplate
